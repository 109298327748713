import React from "react";
import StoryPage from "../../components/story-page";
import { scrollToElm } from "../../components/dom";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import { FirebaseContext } from "../../services";
import "firebase/firestore";

/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const { db, isInitialized } = React.useContext(FirebaseContext);
  const [carouselUrls, setCarouselUrls] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const imgUrl =
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2Fbbdc2a-46f-1a06-a1cc-c7fd84dee5d?alt=media&token=b45bf57a-6fc0-4aef-8207-fafd43b582ad";
  const fullName = `Catherine Yeoh`;
  const zoomUrl =
    "https://us02web.zoom.us/j/83275553747?pwd=ekJyUStScTYvK3JUM1MwNDNadUgzUT09";
  const zoomPhoneNumber = "(647) 558-0588 ";
  const zoomMeetingId = "832 7555 3747 ";
  const zoomMeetingPasscode = "285796";
  const zoomMeetingFooter = (
    <>
      <p></p>
    </>
  );
  const birthDate = new Date(1947, 8 - 1, 30);
  const deathDate = new Date(2021, 2 - 1, 10);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 2 - 1, 27);
  const memorialTime = "10 am EST";
  const inviteText = `Catherine – loving mother, supportive wife, dear friend, globe trotting adventurer, inspirational light – is finally resting in peace after a long battle with Parkinson’s Disease. Please join us in celebrating her life and sharing her memories. During the service, please bring a candy or chocolate to participate in a “sweet” send-off and wear bright coloured clothing.`;
  const inviteOverride = (
    <>
      <h2>
        <strong>Catherine's Obituary</strong>
      </h2>
      <button
        onClick={() => {
          scrollToElm("footer-obit");
        }}
      >
        Click to Read
      </button>
    </>
  );
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/f66ekebsm";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r2a9gxhf3u95wah68zz422lfzg5t4dey9d0d8z63";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Catherine%20Yeoh%20service.mp4",
      title: "Memorial Recording",
      poster: "/images/people/CatherineYeohPoster.png",
    },
  ];
  const dropboxUrl = "https://www.dropbox.com/request/W05fE2OOlbOZw69pZySl";
  const pageFooter = (
    <section
      className="aw-accent"
      sx={{
        marginTop: ["0", "20px"],
      }}
    >
      <div className="aw-content">
        <h2 id="footer-obit">
          <strong>Catherine's Obituary</strong>
        </h2>
        <div className="aw-rte">
          <p className="ql-align-justify">
            <span>Dear family and friends,</span>
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            <span>
              It is with great sadness that we are announcing the passing of
              Catherine Ngan-Han Yeoh.&nbsp;Catherine passed away peacefully,
              and on her own terms, on February 10, 2021 at Mackenzie Health
              Hospital in Richmond Hill.&nbsp;She is survived by her adoring
              husband, Sonny (Soon Hin) Yeoh and her two loving children,
              Jessica and Kevin.
            </span>
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            <span>
              Born on August 30, 1947 in Canton, China in the post-World War II
              era, Catherine was the middle child in a family of two older
              sisters (Jenny and Matilda), two younger brothers (Lawrence and
              Michael), and one younger sister (Miranda).&nbsp;She was raised
              and schooled in Hong Kong, where she established&nbsp;
            </span>
            lifelong friends at St. Francis of Assisi.&nbsp;Seeking a new world
            with different opportunities, Catherine moved to London, England
            when she was 18 years old where she was offered a job by a prominent
            solicitor as a legal secretary.&nbsp;Through the approximately
            ten&nbsp;years in this position, Catherine developed a strong
            foundation rooted in a hard and determined work ethic, integrity,
            and loyalty.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            One fateful night in 1969, Catherine met Sonny at a bar (despite
            being a non-drinker!) in England where their relationship blossomed
            over a long-distanced 10-year span.&nbsp;Catherine and Sonny married
            on January 2, 1980.&nbsp;Shortly afterward, on November 15, they
            welcomed their first-born child, Jessica, in Banbury, England before
            moving to Malaysia with Sonny’s family just three weeks
            later.&nbsp;On March 6, 1982, the family found their permanent home
            in Toronto, Canada, where exactly one year later, Kevin was born.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            You all may not know this, but in moving to Canada, Catherine
            fulfilled her destiny in becoming a master juggler!&nbsp;She was
            juggling a new family, a new home, and a new travel business (which
            she knew nothing about!).&nbsp;However, it was through this “career”
            that Catherine developed many new relationships by meeting new
            people and fostering profound friendships.&nbsp;She learned how to
            be a mother, wife, business person, neighbour, and friend.&nbsp;She
            also found a new love in her life – travel – in which she enjoyed
            taking others around the world to explore, educate, and experience
            different places, cultures, and ways of life.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            Catherine’s passion for travel took her to many countries where she
            was able to experience the rich diversity of the globe.&nbsp;A
            common saying of hers was that “variety is the spice of life”, an
            adage surely shaped by her globetrotting adventures.&nbsp;However,
            Catherine’s favourite aspect of traveling was the people she came
            into contact with and the relationships she created – not only from
            the local people, but especially from the experiences she shared
            with her clients and friends.&nbsp;Catherine also felt fortunate to
            be able to incorporate her family life with her business life,
            imparting the wonder, excitement, and education of all the world had
            to offer with Jessica and Kevin.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            Through the years, Catherine continued to learn and adapt to
            constantly evolving circumstances in the travel
            industry.&nbsp;Starting Reliance Travel, pivoting to Reliance
            Destination Management Consultants, opening Popular Choice Travel,
            and shifting focuses between retail, corporate, and specialized
            private group travel, Catherine did everything she could to meet the
            challenges of a dynamic, but exciting industry.&nbsp;
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            Sadly, at the peak of her career in 1996, Catherine was diagnosed
            with early onset Parkinson’s Disease at only 49 years
            old.&nbsp;Though a devastating prognosis, Catherine’s will and
            determination (she liked to call it stubbornness) helped her manage
            PD for many years.&nbsp;Yet, in 2009, Catherine’s fight against PD
            was further complicated with severe spinal issues, which eventually
            led to five back surgeries, among other afflictions.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            As many of you know, Catherine chose to leave this world on her own
            terms after many years of battling the physical, emotional and
            mental suffering caused by her debilitating conditions.&nbsp;True to
            form in her spirit of giving and kindness, for her final act, she
            chose to donate her tissue and organs to those in need, as well as
            her brain for advancing Parkinson’s Disease research in hopes of
            helping others.
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            Catherine wanted to make a special acknowledgement for the care,
            love, and support given to her by family, friends, and the army of
            healthcare workers (doctors, specialists, pharmacists, nurses, PSWs,
            caretakers, administrators and custodial staff) over this
            span.&nbsp;She also wanted to note her appreciation for all of her
            loyal clientele, her dedicated staff, and many mentors through the
            years.&nbsp;Thank you all for your generosity and kindness.
          </p>
          <p className="ql-align-justify">
            With Catherine’s passing, we hope that most people that she came in
            contact with will remember her as a caring mother, a supportive
            spouse, a genuine friend, a cari
            <span>
              ng sibling, and a stubborn (but determined!) business
              person.&nbsp;In leaving our physical world, Catherine can now join
              other family and friends that have passed on before her in
              peace.&nbsp;She will forever be in our hearts and minds.
            </span>
          </p>
          <p className="ql-align-justify">
            <br />
          </p>
          <p className="ql-align-justify">
            <span>
              In lieu of flowers, please send all donations to Parkinson Canada
              to help aid in research and support for other affected families.
            </span>
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </section>
  );

  // FAIL SAFE
  const carouselImages = [
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F015565e-db5b-1fc4-5dde-58e30d8ed00?alt=media&token=212f85dd-fec4-4106-aaf0-24c4c4b1e053",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F016380-07df-de1a-0ef7-4d74dd0da44?alt=media&token=d683f90d-e266-4637-b8a4-cc451241c419",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F0540e8d-b3c-2baa-4b5d-5b1b45827c03?alt=media&token=4f323e8a-f148-45bd-93b3-c95152d3e03c",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F055bc7-7a1-166a-324-4c0cb3b5c83?alt=media&token=971e0162-af0e-4ce9-b2da-c3bd3b3d83ba",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F0d87ab-b725-ba72-a384-8387584343f3?alt=media&token=be3c6571-9313-43a3-a2c5-5252283c4cb7",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F0fed3c8-cd48-6318-f025-1f34f0f1205a?alt=media&token=261f608b-6fe3-42ad-8002-b8dd313ba3c2",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F1420781-3a3b-2a25-0767-bf6a00850f7?alt=media&token=829b0049-0092-4b98-9b00-48ac09177e4e",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F157b1f3-338-dade-82a0-8f226038de?alt=media&token=e42f8ad9-b0f2-4947-9d1e-96e7e3202117",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F1c271d-065a-bff-ea4d-fd756e64336d?alt=media&token=bc5fd93f-0f43-400e-a486-6fa2c0edf26e",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F1de4d56-30f4-de00-d512-b0572f5450be?alt=media&token=50915cca-ab1f-499a-9a20-31bbfd756b51",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F20ba2a0-7d7e-635-e7c7-013628f1471?alt=media&token=f8b107a7-985b-4f4a-a0d3-bcfdc54447b6",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F24b7e77-3b26-5d20-47b-327c6a2cdf1?alt=media&token=081e0bfc-29d9-4a92-976f-04726ad44414",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F25aaac0-d4-348b-8f00-85b7f411adb0?alt=media&token=e3c95c5e-da80-45c4-8a96-7c75bcce3df6",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F27d4e2f-38c4-46c0-0bcc-a5262eacafb?alt=media&token=6e589bbc-b064-4364-a5b2-6bb8d5cadb5d",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F2b4ce66-4772-f6fd-1e78-efa511274?alt=media&token=394d1b79-1c0e-464f-ab15-9746dd1e0b5d",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F2b842f7-a3c7-2e37-e74-e86416fbf001?alt=media&token=0a57cc06-13cf-427e-b150-e29cf4f4d82c",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F2dd614c-ac03-54f-ceee-382f5ac35b68?alt=media&token=29ee296e-67d6-45c6-aa8c-64f5c8c3645e",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F32d70-c7e2-afbe-755a-dda806d666e5?alt=media&token=c0f2d5f2-c7d6-4822-8b8f-63915d6da3f0",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F337222a-8a88-2b46-53b5-e00558340?alt=media&token=cdf64b69-c04f-4789-8ac0-b0803685de02",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F3a5a1b7-d836-a67f-07f0-3a30b8c01f7?alt=media&token=7c9e98e2-3dd6-4df4-9d60-4967237cbfd2",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F3b065-fce7-8234-42d-f4138d71ec10?alt=media&token=74c1ab00-3d2e-42e1-b6e0-5195cdb4375b",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F3edf66b-1e5a-1de1-12b3-8f81c270bad?alt=media&token=aab93ae2-d2f1-4568-a64b-40a9baf8a474",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F4025f78-a30-02c-43a-1ba1e6230e?alt=media&token=f286e66c-edf1-46a1-81fc-098d4de89cb3",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F47a05b5-33a4-fc65-1dbf-b58c056e3804?alt=media&token=72c15308-17d9-449d-970b-3dcd009e0c75",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F4b26c58-ec2f-14e0-5e17-d0ba2c0b144?alt=media&token=854993d2-db0a-4bad-966d-5e22be497154",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F510e06f-377d-10e3-fdd0-37b87e613fb?alt=media&token=66956a1a-547e-4728-b8df-6b611c1b559d",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F51c03-22ab-41f3-baa-e2ec3b05fa01?alt=media&token=19e57dbd-d798-4d2f-b769-052f74080b0e",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F528213a-754e-2da3-ac64-0df343c3b57?alt=media&token=2903202e-055f-4af2-8c34-96519a82f037",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F55d56b-aa2-cd58-2e1b-15f60d16b30?alt=media&token=a6a4f892-6f49-4c78-a8c4-6b64e35a5df3",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F585d8-86c8-2d3-dd74-08f4d717b8c8?alt=media&token=d49be80d-24a2-4259-b470-50af046046ed",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F5a88d1a-2612-4a55-7ccc-5aa18b30a5f4?alt=media&token=acdf93ae-144f-4a17-8d30-824916657cd3",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F5af75d3-5272-88c-42fb-bea0561aad?alt=media&token=9a688248-3314-4183-9280-eedb81d04028",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F5d6f77-0583-3f13-3507-1db5248c8ed?alt=media&token=6a215226-65c2-4f4e-bd34-1fa2eb3672f9",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F5dc8c40-1de0-fe34-332-bf16782e64dd?alt=media&token=9214b01b-d672-4f9a-ab88-b5fabbaeed31",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F5e688ba-4530-1b1b-33f-57bedc80335?alt=media&token=e7614817-3531-45f6-ad93-e6e72d165e19",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F6148d4-e2ff-5343-3b8-c3b6aefdc62e?alt=media&token=e4df41a6-955f-41be-bdf4-27ac12823b7d",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F6b7a3d5-d1d4-a6dc-51a0-ffa57b3c8b1?alt=media&token=5b1b5b9a-6f5a-4eb2-af77-8e6ee8cff586",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F77dc-02a4-3eeb-f211-c4f65d014f63?alt=media&token=88671a99-326c-4b17-838a-3bfc553c52c3",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F77ece4-75a8-8cd4-8224-872b42611fb?alt=media&token=40acbfe8-68a6-4d4a-8e9c-6a6b4664c6f1",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F7d7f1-7dc-4d7d-5ef3-bae22d1278a?alt=media&token=a3c0b205-c744-4c0b-90ac-503c0a0036e6",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2F84276ec-b0f1-20a2-284-c7853a2bb?alt=media&token=ead2f847-0c91-4827-9108-909652381cab",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fa2b5343-072-a3db-5fc-bf13ff762c07?alt=media&token=48837cd9-b22f-494d-95fa-c620721377d9",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fa41cfb5-6574-c288-f47f-b2dd0efd2367?alt=media&token=6f4563ec-268e-4eb8-87fe-18cf468b04fa",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fa5372c1-ac5b-7e11-58cc-6b341ff6680?alt=media&token=faea1878-808e-49c3-8c55-3ccafd203e44",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fa871344-234-6176-160b-51236ff5ed0f?alt=media&token=4d5fafda-8e5e-4db0-bc86-f457bce2d4ed",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Faf73e66-1737-b861-676c-5ab1b32d4860?alt=media&token=69767bb1-a1d4-4930-81d1-95bae8078fde",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fb44b17-7e1b-53f3-b3d2-f60a5d126e?alt=media&token=11af2966-1cb2-4578-9f98-78f844f3355a",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fb763068-a03-1de-c625-bab0ef5fde3?alt=media&token=c70717a8-702c-4cfb-987c-f6edadb745c9",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fbbf1cbe-3761-12b0-0774-6dbca712a5?alt=media&token=af925fb5-730a-4587-bc95-222fae657b7c",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fbe7f41c-ba50-258c-1b80-71802dd220a?alt=media&token=c720923e-2847-49d6-b56b-a77b43529b83",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fbec6e05-6b70-e424-463d-fd0f2a88efd8?alt=media&token=41ba161b-4e55-40e6-81c5-c72dbcbf6ccf",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fc42bdc5-fc2f-dc8-8351-d13be6de65d1?alt=media&token=98f928c1-81bc-4434-bbce-8107d33b32a7",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fc6b8c02-46-fa88-884-20f001cfbb84?alt=media&token=64e02c71-5d78-4076-aa66-de72cecabc40",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fca2f57-51c-320c-7317-074706a07eb?alt=media&token=881bcd45-427c-4b78-b840-be4104606779",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fd1dda70-54aa-b5b6-f4b-8c112d03ad?alt=media&token=9ed47c9e-6aa5-4462-af4e-7d93df1fde32",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fe00336-7a0-cbdd-30f8-f0b2bf2a57a?alt=media&token=7102850b-3558-4cf6-b304-f3b6eaa90d9a",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fe7fa0a6-2af6-1b61-412c-f7484220b86?alt=media&token=4f8c6b90-cb10-42a6-b636-d7f24d0b1c07",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Febdf62-fdad-f48a-b0e2-6b4103b5df?alt=media&token=3bbe8117-018d-4f2d-85d7-a6969a096fef",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Ff87e7-32dc-f672-06e-a15e54e736d?alt=media&token=30232b65-bf96-4c8d-bd71-9d8caa4f7a2a",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Ffb5abb4-56ac-a64-010b-ce7efe64b1b?alt=media&token=a951d570-d2ad-41d2-8d5d-fa09320addc5",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Ffdcbabb-2e4a-b46-dfc-c80147e12d5?alt=media&token=a834b96f-8dbc-4cc7-9d90-4c35c61e3387",
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F2qyxvWJF9BM47YSwcora%2Fff8a51d-e5f3-c783-def7-bc730827a527?alt=media&token=d59c4938-3afe-4b98-bfc0-55ab4df3dd52",
  ];

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }
    db.collection("humans")
      .doc("2qyxvWJF9BM47YSwcora")
      .get()
      .then(function (result) {
        let data = result.data();
        setCarouselUrls(data.carouselUrls || carouselImages);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, [isInitialized]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/react-responsive-carousel@3.2.12/lib/styles/carousel.min.css"
        ></link>
      </Helmet>
      <StoryPage
        location={location}
        imgUrl={imgUrl}
        fullName={fullName}
        zoomUrl={zoomUrl}
        zoomPhoneNumber={zoomPhoneNumber}
        zoomMeetingId={zoomMeetingId}
        zoomMeetingPasscode={zoomMeetingPasscode}
        customColumnSpacing="2fr 3fr"
        birthDate={birthDate}
        deathDate={deathDate}
        birthDeathFooter={birthDeathFooter}
        memorialDate={memorialDate}
        memorialTime={memorialTime}
        inviteText={inviteText}
        zoomMeetingFooter={zoomMeetingFooter}
        inviteOverride={inviteOverride}
        obituary={obituary}
        storiesRecordUrl={storiesRecordUrl}
        storiesSubmissionsUrl={storiesSubmissionsUrl}
        recordingEmbedDirectUrl={recordingEmbedDirectUrl}
        dropboxUrl={dropboxUrl}
        pageFooter={pageFooter}
      >
        {isLoaded && (
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            dynamicHeight={true}
            sx={{ marginTop: "24px", maxHeight: "600px" }}
          >
            {carouselUrls.map((img, idx) => {
              return (
                <div key={`carousel-img-${idx}`}>
                  <img src={img} alt={`Picture of ${fullName}`} />
                </div>
              );
            })}
          </Carousel>
        )}
      </StoryPage>
    </>
  );
};

export default ThisPage;
